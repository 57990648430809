/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "A company that fulfills the role of DNS for snail mail. You register a static address with the service and whenever you move you only have to update your address with the service. All mail will be delivered to their facility where they will deliver it via private courier."), "\n", React.createElement(_components.p, null, "It is designed for dense cities where moving at least once a year is not uncommon. If a major move is made you would simply unregister with the service and notify the appropriate parties of the address change as you would today. Hmm, maybe this service could provide an interface to the national mail service which would act like a hash table lookup of your registered name to your current address. Well, that might be all that’s needed…"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
